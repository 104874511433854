/* .gmap_iframe {
  aspect-ratio: 16 / 12;
  max-width: 1000px;
  width: 100%;
  height: auto;
} */
/* .map-container {
  margin: 0 20px;
  width: 500px;
} */
#top-button,
#development-button,
#company-button,
#access-button,
#contact-button {
  cursor: pointer;
}

.maps-container {
  text-align: center;
}

.google-map {
  border: 0px;
  width: 500px;
  height: 400px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .google-map {
    width: 100% !important;
    padding: 20px;
    max-width: 500px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@600&display=swap');

.image-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.responsive-image {
  width: 100%;
  height: auto;
  display: block;
}

.text-overlay {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center; /* 上下中央 */
  align-items: center; /* 左右中央 */
  width: 100%;
  text-align: center;
}

.text-overlay h1 {
  font-size: 6.3vw;
  display: flex;
  justify-content: center;
  align-items: baseline; /* "0" の高さを文字基準に */
  gap: 5px;
  white-space: nowrap;
  font-family: "Zen Old Mincho", serif;
  font-weight: 900;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  margin-bottom: -0vh; /* 説明文との間隔を確保 */
}

#h1-number {
  font-size: 2em; /* h1 のサイズに応じて自動調整 */
  line-height: 1;
  margin: 0 5px;
}

.description {
  font-size: 1.9vw;
  line-height: 1.5;
  font-family: "Zen Old Mincho", serif;
  font-weight: 900;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  width: 80%;
  letter-spacing: -0.05em;
}
