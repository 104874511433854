/* style.css */
#office, #access, #contact-us {
    scroll-margin-top: 70px !important;
}
/* Google Mapを囲う要素 */
.map {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 75%; /* 比率を4:3に固定 */
}
@media (min-width: 640px) {
    .map {
        width: 600px;
        height: 450px; /* 高さを450pxに設定 */
        padding-top: 0; /* パディングトップを無効化 */
    }
}
/* Google Mapのiframe */
.map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.Adress {
    margin: 40px;
}