.container {
  max-width: 720px;
  margin: 0 auto;
}

.highlight {
  border-left: 5px solid #6DC466;
  padding: 10px 0px 10px 20px;
  font-weight: 600;
  font-size: 20px;
  margin: 20px 0px;
}

.text-block {
  padding-left: 20px;
  margin-bottom: 5px;
}
.name-orange {
  color: #e87d13;
  font-weight: 600;
  padding: 10px 0px;
}
.name-green {
  color: #59c228;
  font-weight: 600;
  padding: 10px 0px;
}
.url {
  color: #59c228;
}
.date {
  text-align: right;
}